import { OverlayScrollbars } from 'overlayscrollbars';
import { throttle } from 'lodash-es';

export const initializeOverlayScrollbars = (contentsRef: HTMLElement): OverlayScrollbars | null => {
    if (!contentsRef) return null;
    return OverlayScrollbars(contentsRef, {
        overflow: { x: 'visible', y: 'scroll' },
    });
};

export const scrollToAnswer = throttle((osInstance: OverlayScrollbars, getElementById: string, ignoreAutoScroll: boolean = false, position: 'top' | 'middle' | 'bottom' = 'middle') => {
    if (!osInstance || ignoreAutoScroll) return;

    const answerElement = document.getElementById(getElementById);
    if (!answerElement) return;

    const { viewport } = osInstance.elements();
    const viewportRect = viewport.getBoundingClientRect();
    const answerRect = answerElement.getBoundingClientRect();
    const scrollTop = viewport.scrollTop;

    const targetScrollPosition = (() => {
        switch (position) {
            case 'top':
                return answerRect.top + scrollTop;
            case 'middle':
                return answerRect.bottom - (viewportRect.top + viewportRect.height / 2) + scrollTop;
            case 'bottom':
                return answerRect.bottom - viewportRect.height + scrollTop;
            default:
                return 0;
        }
    })();

    viewport.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
}, 500);
