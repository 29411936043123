import { PUBLIC_COMMON_API_BASE_PATH, PUBLIC_COMMON_API_HOST } from '$env/static/public';
import { browser, dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';
import { locale, _ } from 'svelte-i18n';
import type { AxiosInstance, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios';
import { createAxiosInstance, toastDanger } from '$lib/utils';
import { actionSignOut, auth } from '$stores/auth';

const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const ERROR_ID = crypto.randomUUID();

let isShowingUnauthorizedError = false;

export function createServiceAxiosInstance(serviceRoute: string, config?: CreateAxiosDefaults) {
    const baseURL = `${PUBLIC_COMMON_API_HOST}${PUBLIC_COMMON_API_BASE_PATH}${serviceRoute}`;
    const instance = createAxiosInstance({ baseURL, timeout: 10000, withCredentials: true, ...config });

    setupServiceInterceptors(instance);

    return { instance, baseURL };
}

export function setupServiceInterceptors(instance: AxiosInstance) {
    instance.interceptors.response.use(
        async function (response) {
            return response;
        },
        async function (error) {
            // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 오류가 있는 작업 수행

            const { url } = error.config;

            // 인증 오류
            // 브라우저에서만 동작
            if (browser) {
                const status = error.response?.status;

                const unauthorized = status === STATUS_UNAUTHORIZED && !url.includes('/login/v') && !url.includes('/login/super-admin/v');

                if (unauthorized || (status === STATUS_FORBIDDEN && url?.includes('/refresh/v'))) {
                    const unauthorizedError: any = new Error(get(_)('apps.contract.lib.api.helper.unauthorizedError'));
                    unauthorizedError.config = { ...error.config, ignoreSentry: true };

                    if (!isShowingUnauthorizedError) {
                        isShowingUnauthorizedError = true;
                        showError(unauthorizedError);

                        if (!url?.includes('/refresh-after/v') && !url?.includes('/logout/v')) {
                            actionSignOut();
                        }
                    }
                } else if (status === STATUS_FORBIDDEN) {
                    // 권한 오류
                    const forbiddenError: any = new Error(get(_)('apps.contract.lib.api.helper.forbiddenError'));
                    forbiddenError.config = { ...error.config };
                    showError(forbiddenError);
                } else {
                    showError(error);
                }
            }

            return Promise.reject(error);
        },
    );

    instance.interceptors.request.use(
        async function (config: InternalAxiosRequestConfig) {
            // 공통 헤더 설정
            setCommonHeaders(config);

            isShowingUnauthorizedError = false;
            return config;
        },
        function (error) {
            // 요청 에러 핸들링
            return Promise.reject(error);
        },
    );
}

function setCommonHeaders(config) {
    const { tenantId, workspaceId } = get(auth);
    const localeValue = get(locale);

    // 추출한 정보를 헤더에 설정, Request Header에 설정된 값이 있으면 우선 사용
    config.headers['X-Tenant'] = config.headers['X-Tenant'] ?? (tenantId || 0);
    config.headers['X-Workspace'] = config.headers['X-Workspace'] ?? (workspaceId || 0);
    config.headers['X-Locale'] = config.headers['X-Locale'] ?? (localeValue || 'ko');
}

function showError(error) {
    const errorMsg = error.response?.data.message || error.message;
    const status = error.response?.status;

    const ignoreErrorMessage = error.config?.ignoreErrorMessage;
    if (!ignoreErrorMessage) {
        toastDanger(`${status ? `(${status}) ` : ''}${errorMsg}`);
    }

    const ignoreSentry = error.config?.ignoreSentry;
    if (!dev && !ignoreSentry) {
        Sentry.captureException(error, { extra: { ERROR_ID } });
    }
}
