import { ToastSimpleContent } from '@allibee/components/basic';
import { toast, type SvelteToastOptions } from '@zerodevx/svelte-toast';

import { groupBy } from 'lodash-es';

/**
 * 토스트 스택에 같은 메시지가 여러개 있는 경우 최신 메시지만 남기고 이전 메시지를 제거
 */
toast.subscribe(toasts => {
    const groupedToasts = groupBy(toasts, 'msg');
    const duplicatedToasts = Object.values(groupedToasts).flatMap(group => group.slice(0, group.length - 1));

    duplicatedToasts.forEach(t => toast.pop(t.id));
});

export function toastDefault(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'gray',
            },
            sendIdTo: 'toastId',
        },
        duration: 2000,
        pausable: true,
        dismissable: false,
        ...options,
    });
}

export function toastSuccess(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'green',
            },
            sendIdTo: 'toastId',
        },
        duration: 2000,
        pausable: true,
        dismissable: false,
        ...options,
    });
}

export function toastDanger(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'red',
            },
            sendIdTo: 'toastId',
        },
        duration: 2000,
        pausable: true,
        dismissable: false,
        ...options,
    });
}
